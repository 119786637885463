import { reactive, defineComponent } from "vue";
import { useRouter } from "vue-router";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "ListIncentives",
    setup: function () {
        var router = useRouter();
        var state = reactive({});
        return {
            state: state,
            routePath: routePath
        };
    }
});
