<template>
  <div class="list_incentives">
    <div class="list_incentives__box">
      <div class="list_incentives__box__content">
        <div class="list_incentives__box__content__tabs">
          <router-link
            class="tab-item"
            :to="{ path: routePath.ORGANIZATION_DASHBOARD_PROJECTS }"
          >
            プロジェクト一覧
          </router-link>
          <router-link
            class="tab-item"
            :to="{ path: routePath.ORGANIZATION_DASHBOARD_INCENTIVES }"
            v-if="false"
          >
            オファー一覧
          </router-link>
        </div>
        <div class="list_incentives__box__content__table">
          <!-- content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/organization/dashboard/list-incentives/ListIncentives.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/organization/dashboard/list-incentives/ListIncentives.scss" scoped>
</style>